@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');
body {
    background-color: #000000 !important;
    color: #ffff;
    margin: 0;
    font-weight: 300;
    line-height: 1.5em;
    font-family: 'Lato', sans-serif;
}

a {
    color: #000000;
    text-decoration: none;
}
.datetime {
    line-height: 1.1;
}
.time {
    font-size: 25px;
    font-weight: normal;
    opacity: 0.8;
}
.time-suffix {
    font-size: 25px;
    font-weight: lighter;
}
.date {
    font-size: 17px;
    opacity: 0.8;
    font-weight: lighter;
    text-align: center;
}
.mainContainer {
    position: relative;
    top: 60px;
    margin: 0 auto;
    padding-top: 120px;
}